<template>
  <div class="detail">
    <baseContainer back>
      <el-form label-width="100px">
        <baseSection name="设备信息">
          <baseRow justify="space-between">
            <baseCol>
              <el-form-item label="类型名称：" prop="name">{{ form.name }}</el-form-item>
            </baseCol>
            <baseCol>
              <el-form-item label="类型代码：" prop="code">{{ form.code }}</el-form-item>
            </baseCol>
            <baseCol></baseCol>
          </baseRow>
        </baseSection>
        <baseSection name="上传资料">
          <baseRow>
            <baseCol>
              <el-form-item label="类型图标：">
                <img :src="form.iconUrl" alt="" class="img" />
              </el-form-item>
            </baseCol>
          </baseRow>
        </baseSection>
      </el-form>
    </baseContainer>
  </div>
</template>

<script>
import baseContainer from '@/components/container/base-container';
import baseSection from '@/components/section/base-section';
import baseRow from '@/components/row/base-row';
import baseCol from '@/components/col/base-col';

export default {
  props: ['deviceTypeId'],
  data() {
    return {
      form: {
        name: '',
        code: '',
        iconUrl: ''
      },
      code_list: []
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.form = await this.$apis.deviceType.get({ deviceTypeId: this.deviceTypeId });
      this.form.iconUrl = process.env.VUE_APP_ICONIMG_URL + this.form.iconUrl;
    }
  },
  components: {
    baseContainer,
    baseSection,
    baseRow,
    baseCol
  }
};
</script>

<style lang="scss" scoped>
.el-input,
.el-select {
  width: 100%;
}
.img {
  width: 90px;
}
</style>
