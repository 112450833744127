<template>
  <div class="base-container">
    <div class="hd">
      <el-button class="iconfont icon-dcarrow-left" @click="goBack" v-if="back">返回</el-button>
      <el-button type="primary" class="iconfont icon-dcicon_save1" @click="subimit" v-if="submit">保存</el-button>
      <el-button class="iconfont icon-dcx" @click="goBack" v-if="cancel">取消</el-button>
      <slot name="customBtns"></slot>
    </div>
    <div class="main">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    back: Boolean,
    submit: Boolean,
    cancel: Boolean,
    btns: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    subimit() {
      this.$emit('submit');
    }
  }
};
</script>

<style lang="scss" scoped>
.main {
  margin-top: 15px;
}
</style>
